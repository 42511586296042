import { IIncomingInventoryItem, IProductSimple, PriceTier, UnitOfMeasureData } from '../types/product'
import numeral from 'numeral'

export const getFormattedProductPrice = (
  product: IProductSimple
): Record<string, (PriceTier & { salePrice?: number; hasConflictingPriceTiers?: boolean })[]> => {
  const pricing: Record<string, (PriceTier & { hasConflictingPriceTiers?: boolean })[]> = {}
  const variantsPriceTiers = product.variants?.reduce<(PriceTier & { hasConflictingPriceTiers?: boolean })[]>((acc, variants) => {
    if (variants.pricing?.priceTiers) {
      acc.push(
        ...variants.pricing.priceTiers.map((priceTier: PriceTier & { hasConflictingPriceTiers?: boolean }) => ({
          ...priceTier,
          hasConflictingPriceTiers: variants.hasConflictingPriceTiers,
          salePrice: variants.salePrice,
        }))
      )
    }
    return acc
  }, [])
  const pricingPriceTiers = product.properties?.pricing?.priceTiers ?? []

  let priceTiers = variantsPriceTiers

  // add fallback to price tiers from pricing object to not
  // break pricing while variants pricing is not returned
  if (priceTiers.length === 0) {
    priceTiers = pricingPriceTiers
  }
  priceTiers.forEach((priceTier) => {
    if (priceTier.unitOfMeasure in pricing) {
      pricing[priceTier.unitOfMeasure] = [...pricing[priceTier.unitOfMeasure], priceTier]
    } else {
      pricing[priceTier.unitOfMeasure] = [priceTier]
    }
  })
  return pricing
}

export const getTotalIncomingInventories = (incomingInventories?: IIncomingInventoryItem[]): number => {
  if (!incomingInventories) {
    return 0
  }
  return incomingInventories.reduce((acc, curr) => {
    acc += curr.quantity
    return acc
  }, 0)
}

export const formatQuantity = (quantity: number, uomData: UnitOfMeasureData[], selectedUom: string | null | undefined): string => {
  const selectedUomData = uomData.find((uom) => uom.uom === selectedUom)
  if (selectedUomData) {
    const uomQuantity = selectedUomData.quantity ?? 1
    return `${quantity.toLocaleString('en-US')} (${Math.floor(quantity / uomQuantity).toLocaleString('en-US')} ${selectedUomData.uom})`
  }
  return `${quantity.toLocaleString('en-US')}`
}

export const getFormattedQuantity = (
  rowQuantity: number,
  uomData: UnitOfMeasureData[],
  selectedUom: string | null | undefined,
  config?: { withoutUom?: boolean }
): string => {
  if (config?.withoutUom) {
    return `${(rowQuantity ?? 0).toLocaleString('en-US')}`
  }
  if (uomData) {
    const selectedUomData = uomData.find((uom) => uom.uom === selectedUom)
    if (selectedUomData?.quantity && selectedUomData.uom && rowQuantity) {
      return `${Math.floor(rowQuantity / selectedUomData.quantity).toLocaleString('en-US')} ${selectedUomData?.uom?.toUpperCase()}`
    }
  }
  return `${(rowQuantity ?? 0).toLocaleString('en-US')}`
}
export type PriceWithSale =
  | {
      from: string
      salePrice?: string
    }
  | { fixed: string; salePrice?: string }
  | { note: string }
  | null

export const getPriceWithSale = (product: IProductSimple, selectedUom: string): PriceWithSale => {
  const pricingFormat = '$0,0.0000'
  if (product && selectedUom) {
    const pricing = getFormattedProductPrice(product)
    if (selectedUom in pricing) {
      const uomPrice = pricing[selectedUom]
      if (uomPrice.length > 1) {
        const lowestPrice = Math.min(...uomPrice.map((p) => p.price))

        if (uomPrice.findIndex((price) => price.hasInvalidPrice === true || price.hasConflictingPriceTiers === true) >= 0) {
          return { note: 'Pricing to be confirmed.' }
        }

        const lowestSalePrice = Math.min(...uomPrice.map((p) => p.salePrice ?? 0))
        if (lowestSalePrice > 0 && lowestSalePrice < lowestPrice) {
          return { from: numeral(lowestPrice).format(pricingFormat), salePrice: numeral(lowestSalePrice).format(pricingFormat) }
        } else {
          return { from: numeral(lowestPrice).format(pricingFormat) }
        }
      } else {
        if (uomPrice[0].hasInvalidPrice || uomPrice[0].hasConflictingPriceTiers) {
          return { note: 'Pricing to be confirmed.' }
        }
        if (uomPrice[0].salePrice && uomPrice[0].salePrice > 0 && uomPrice[0].salePrice < uomPrice[0].price) {
          return {
            fixed: numeral(uomPrice[0].price).format(pricingFormat),
            salePrice: numeral(uomPrice[0].salePrice).format(pricingFormat),
          }
        } else {
          return { fixed: numeral(uomPrice[0].price).format(pricingFormat) }
        }
      }
    }
  }
  return null
}
