import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import config from '../../config'

export interface IUiState {
  isOpen: Array<string>
  errorModal: { message: string } | undefined
  fontFamily: string
  borderRadius: number
  opened: boolean
  mode: ThemeMode
  featureFlags?: Record<string, boolean>
}

export type ThemeMode = 'dark' | 'light'

const initialState: IUiState = {
  isOpen: [],
  errorModal: undefined,
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
  mode: 'light',
  featureFlags: {
    '6084_UpdatedCustomerSupportCard': true,
    '4538_GTranslatePlugin': true,
  },
}

export const uiSlice = createSlice({
  name: 'uiStore',
  initialState,
  reducers: {
    setErrorModal: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.errorModal = payload
    },
    resetErrorModal: (state) => {
      state.errorModal = undefined
    },
    setOpenedMenu: (state, { payload }: PayloadAction<string | undefined>) => {
      state.isOpen = payload ? [payload] : []
    },
    setMenuState: (state, { payload }: PayloadAction<boolean>) => {
      state.opened = payload
    },
    setFontFamily: (state, { payload }: PayloadAction<string>) => {
      state.fontFamily = payload
    },
    setBorderRadius: (state, { payload }: PayloadAction<number>) => {
      state.borderRadius = payload
    },
    setFeatureFlags: (state, { payload }: PayloadAction<Record<string, boolean>>) => {
      state.featureFlags = payload
    },
    toggleTheme: (state) => {
      state.mode = state.mode === 'dark' ? 'light' : 'dark'
    },
  },
})

export const { setErrorModal, resetErrorModal, setOpenedMenu, setMenuState, setFontFamily, setBorderRadius, setFeatureFlags, toggleTheme } =
  uiSlice.actions
export const { reducer } = uiSlice
