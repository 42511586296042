import { AxiosRequestConfig } from 'axios'

import { HttpClient } from '../services/HttpClient'
import * as AppConfig from '../config'

import { ApiFeatureFlag } from '../types/ui'

export class ConfigRepository extends HttpClient {
  public getFeatureFlags = async () => {
    const { data } = await this.instance.get<{ data: ApiFeatureFlag[] }>('omni-user/featureflags')

    return data?.data
  }

  constructor() {
    super(AppConfig.API_URL)
    this.initializeRequestInterceptor()
  }

  private static classInstance?: ConfigRepository

  private readonly initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest, this.handleError)
  }

  private readonly handleRequest = (config: AxiosRequestConfig) => {
    config.headers!['Ocp-Apim-Subscription-Key'] = AppConfig.APIM_SUBSCRIPTION_KEY

    const token = window.localStorage.getItem('token')

    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }

    return config
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new ConfigRepository()
    }
    return this.classInstance
  }
}
