import { Stack, Box, useTheme, Grid, Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Drawer, Typography, Button, TextField } from '../../../common'
import { B2CCartItem, B2CCartMatchItem } from '../CartItem'
import { CloseIcon } from '../../../icons'
import { trackClick } from '../../../../utils/segment'

import './../../../../i18n'
import numeral from 'numeral'
import plural from 'pluralize'
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks'
import { selectCartItems, selectPerfectMatchItems } from '../../../../stores/b2c/CartStore/selectors'
import { useEffect, useState } from 'react'
import { addDiscount } from '../../../../stores/b2c/CheckoutStore/checkoutSlice'
import { selectDiscount } from '../../../../stores/b2c/CheckoutStore/selectors'

type Props = {
  open: boolean
  onToggle: () => void
}

const B2CCartDrawer = ({ open, onToggle }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const cartItems = useAppSelector(selectCartItems)
  const discount = useAppSelector(selectDiscount)
  const perfectMatchItems = useAppSelector(selectPerfectMatchItems)
  const [subtotal, setSubtotal] = useState<number>(0)

  const [updatedDiscountCode, setUpdatedDiscountCode] = useState(discount.code || '')

  useEffect(() => {
    setUpdatedDiscountCode(discount.code || '')
  }, [discount])

  const handleChangeDiscountCodeTextField = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUpdatedDiscountCode(evt.target.value)
  }

  const submitDiscountCode = () => {
    dispatch(
      addDiscount({
        code: updatedDiscountCode,
        amount: 30, // TODO: this is hardcoded for now
      })
    )
  }

  useEffect(() => {
    if (cartItems?.length > 0) {
      setSubtotal(
        cartItems.reduce((acc, curr) => {
          acc += (curr.properties.retailPrice || 0) * curr.quantity
          return acc
        }, 0)
      )
    } else {
      setSubtotal(0)
    }
  }, [cartItems])

  const handleClickContinueShipping = () => {
    // @analytics Track Continue Shipping
    trackClick('Product Detail Page - Cart Flyout', {
      category: 'Click | Continue Shipping',
      location: window.location.href,
      page: 'Product Detail Page - Cart',
    })
  }

  const handleClickViewCartCheckout = () => {
    // @analytics Track View Cart & Checkout
    trackClick('Product Detail Page - Cart Flyout', {
      category: 'Click | View Cart & Checkout',
      location: window.location.href,
      page: 'Product Detail Page - Cart',
    })
  }

  // Make changes to the DOM manually.
  const makeImpactOnDOM = () => {
    const spanEle = document.createElement('span')
    spanEle.style.display = 'none'
    document.body.appendChild(spanEle)
    setTimeout(() => {
      document.body.removeChild(spanEle)
    }, 1)
  }

  useEffect(() => {
    if (open) makeImpactOnDOM()
  }, [open])

  return (
    <Drawer open={open} onToggle={onToggle}>
      <Box width={{ xs: '100%', sm: 450 }} height="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          sx={{
            position: 'sticky',
            top: 0,
            pt: 4,
            pb: 2,
            zIndex: 10,
            backgroundColor: theme.palette.common.white,
          }}
        >
          <Typography variant="h2">{t('drawer.cart.title')}</Typography>
          <Button label={<CloseIcon fill={theme.palette.common.black} />} size="small" sx={{ minWidth: 22 }} onClick={onToggle} />
        </Stack>
        {cartItems && cartItems.length > 0 ? (
          // product list
          <>
            <Box pb={30}>
              <Box mx={2}>
                {cartItems.length > 0 &&
                  cartItems.map((item) => (
                    <Grid key={item.id} item xs={12} mx={2} mb={2}>
                      <B2CCartItem data={item} size="small" />
                      {/* {i < cartItems.length - 1 && <Divider />} */}
                    </Grid>
                  ))}
              </Box>
              {/* match items */}
              {cartItems.length === 1 && perfectMatchItems?.length > 0 && (
                <Box px={2} py={3} sx={{ backgroundColor: theme.palette.common.blue700 }}>
                  <Typography variant="h2" px={3}>
                    {t('drawer.cart.title_perfect_matches')}
                  </Typography>
                  {perfectMatchItems.map((item, i) => (
                    <Grid key={item.id} item xs={12} mx={2} mb={2}>
                      <B2CCartMatchItem data={item} size="small" />
                      {i < perfectMatchItems?.length - 1 && <Divider />}
                    </Grid>
                  ))}
                </Box>
              )}
            </Box>
          </>
        ) : (
          // empty state
          <Box mx={2} pb={30} height="90%">
            <Stack spacing={3} height="100%" justifyContent="center" alignItems="center">
              <img alt="Empty cart" src="/images/empty_cart.png" />
              <Typography sx={{ fontSize: 16, textAlign: 'center', px: 5 }}>{t('cart.empty_cart')}</Typography>
            </Stack>
          </Box>
        )}
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{
            position: 'fixed',
            bottom: 0,
            p: 3,
            width: 'inherit',
            zIndex: 10,
            backgroundColor: theme.palette.common.white,
          }}
        >
          {cartItems?.length > 0 && (
            <>
              {/* subtotal */}
              <Stack direction="row" sx={{ width: '100%' }} spacing={1} justifyContent="space-between">
                <Typography sx={{ fontWeight: 'bold', fontSize: 14, textTransform: 'uppercase' }}>
                  {t('drawer.cart.subtotal')} ({plural('Item', cartItems.length, true)})
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: 14, color: theme.palette.common.darkGreen }}>
                  {numeral(subtotal).format('$0,0.0000')}
                </Typography>
              </Stack>
              {/* coupon code */}
              <Box display="flex" sx={{ width: '100%' }}>
                <Box flexGrow={1}>
                  <TextField
                    fullWidth
                    label={t('drawer.cart.textfields.coupon_code')}
                    inputProps={{
                      'aria-label': t('drawer.cart.textfields.coupon_code'),
                    }}
                    InputProps={{ sx: { borderRadius: 0 } }}
                    value={updatedDiscountCode}
                    onChange={handleChangeDiscountCodeTextField}
                  />
                </Box>
                <Button
                  variant="contained"
                  label={t('button_apply')}
                  sx={{ background: theme.palette.common.darkBlue }}
                  onClick={submitDiscountCode}
                />
              </Box>
            </>
          )}
          <Button
            label={t('drawer.cart.buttons.view_cart_and_checkout')}
            variant="contained"
            sx={{
              width: '100%',
              py: 1.5,
            }}
            onClick={() => {
              onToggle()
              handleClickViewCartCheckout()
              navigate('/cart')
            }}
          />
          {cartItems?.length > 0 ? (
            <Button
              label={t('drawer.cart.buttons.continue_shopping')}
              variant="outlined"
              sx={{
                width: '100%',
                py: 1.5,
              }}
              onClick={() => {
                onToggle()
                handleClickContinueShipping()
                navigate('/products/list')
              }}
            />
          ) : (
            <Button
              label={t('drawer.cart.buttons.start_shopping')}
              variant="outlined"
              sx={{
                width: '100%',
                py: 1.5,
              }}
              onClick={() => {
                onToggle()
                navigate('/products/list')
              }}
            />
          )}
        </Stack>

        {/* <Box mt={4} p={2}>
          <AccessoriesBoughtTogether accessories={mockData.accessories} />
        </Box> */}
      </Box>
    </Drawer>
  )
}

export { B2CCartDrawer }
