import { useState, useRef, useEffect } from 'react'
// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, ClickAwayListener, List, ListItemButton, ListItemText, Paper, Popper, Button } from '@mui/material'
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'
// project imports
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { Typography } from '../../../../components/common'
import MainCard from '../../../../components/cards/MainCard'
// assets
import { useAppSelector } from '../../../../hooks/reduxHooks'
import { selectUiState, selectThemeMode } from '../../../../stores/UiStore/selectors'
import { selectUser } from '../../../../stores/AuthStore/selectors'
import { trackNavigationMenu } from '../../../../utils/segment'

// ==============================|| PROFILE MENU ||============================== //
type Props = {
  handleLogout: () => void
}
const ProfileSection = ({ handleLogout }: Props) => {
  const theme = useTheme()
  const customization = useAppSelector(selectUiState)
  const mode = useAppSelector(selectThemeMode)
  const user = useAppSelector(selectUser)
  const [selectedIndex] = useState(-1)
  const [open, setOpen] = useState(false)
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null)

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
      <Button
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          color: theme.palette.common.black,
          textWrap: 'nowrap',
          textTransform: 'none',
          margin: 0,
          width: 'min-content',
          p: '4px',
          '&:hover, &:focus': {
            color: theme.palette.common.black,
            background: 'none',
          },
          '.MuiButton-endIcon': {
            marginLeft: '4px',
          },
        }}
        onClick={() => {
          handleToggle()
          if (!open) {
            // only trigger event when expanding
            trackNavigationMenu('Profile')
          }
        }}
        endIcon={!open ? <KeyboardArrowDownIcon sx={{ color: '#0179C2' }} /> : <KeyboardArrowUpIcon sx={{ color: '#0179C2' }} />}
        ref={anchorRef}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span style={{ fontSize: '14px', lineHeight: '120%', fontWeight: 500, color: theme.palette.common.black }}>
            {user?.firstName} {user?.lastName}
          </span>
          <span style={{ fontSize: '12px', lineHeight: '120%', fontWeight: 500, color: '#0179C2', textDecoration: 'underline' }}>
            {user?.company}
          </span>
        </Box>
      </Button>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
              offset: {
                enabled: true,
                offset: '0, 30',
              },
            },
          ],
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MainCard border={false} elevation={16} content={false} boxShadow sx={{ boxShadow: '0px 6px 6px #00000010' }}>
              <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                <Box sx={{ px: 1 }}>
                  <List
                    component="nav"
                    sx={{
                      width: '100%',
                      maxWidth: 350,
                      minWidth: 200,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: 0.5,
                      [theme.breakpoints.down('md')]: {
                        minWidth: '100%',
                      },
                      '& .MuiListItemButton-root': {
                        mt: 0.5,
                      },
                    }}
                  >
                    {/* logout */}
                    <ListItemButton
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 2,
                        borderRadius: `${customization.borderRadius}px`,
                        ':hover': {
                          backgroundColor: mode === 'dark' ? theme.palette.background.paper : theme.palette.primary.light,
                        },
                      }}
                      selected={selectedIndex === 4}
                      onClick={handleLogout}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            type="text"
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                            }}
                          >
                            Logout
                          </Typography>
                        }
                        sx={{ textAlign: 'right' }}
                      />
                    </ListItemButton>
                  </List>
                </Box>
              </PerfectScrollbar>
            </MainCard>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  )
}

export default ProfileSection
