import * as React from 'react'
import { Box, Modal as MuiModal, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

import { Typography } from '../../common'

// import { CloseIcon } from '../../../components/icons'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '75vh',
  border: '1px solid',
}

export type ModalProps = {
  readonly open: boolean
  readonly title: string
  readonly largeTitle?: boolean
  readonly children: React.ReactNode
  readonly actions: React.ReactNode
  readonly width?: any
  readonly maxHeight?: string
  readonly titleAlign?: any
  readonly color?: any
  readonly onClose: () => void
}

export function Modal({
  open,
  title,
  largeTitle,
  children,
  actions,
  width,
  titleAlign = 'center',
  maxHeight,
  color = '#060D26',
  onClose,
}: ModalProps) {
  // Make changes to the DOM manually.
  const makeImpactOnDOM = () => {
    const spanEle = document.createElement('span')
    spanEle.style.display = 'none'
    document.body.appendChild(spanEle)
    setTimeout(() => {
      document.body.removeChild(spanEle)
    }, 1)
  }

  React.useEffect(() => {
    if (open) makeImpactOnDOM()
  }, [open])

  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ '.MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
    >
      <>
        <Box sx={{ ...style, width: width ?? style.width, maxHeight: maxHeight ?? style.maxHeight }}>
          <IconButton aria-label="close-modal" sx={{ position: 'absolute', top: 20, right: 20 }} onClick={onClose}>
            <Close />
          </IconButton>
          <Typography
            id="modal-modal-title"
            color={color}
            variant="h3"
            sx={{
              ...(largeTitle
                ? {
                    fontSize: '34px !important',
                    lineHeight: '48px !important',
                    fontWeight: 400,
                  }
                : {
                    fontSize: '24px',
                    lineHeight: '28.8px',
                    fontWeight: 600,
                  }),
            }}
            textAlign={titleAlign}
          >
            {title}
          </Typography>
          <Box id="modal-modal-description" sx={{ my: 2, overflow: 'auto', maxHeight: `calc(${maxHeight ?? style.maxHeight} - 150px)` }}>
            {children}
          </Box>
          {actions}
        </Box>
      </>
    </MuiModal>
  )
}
