const config = {
  basename: '/',
  defaultPath: '/dashboard/default',
  fontFamily: 'Montserrat, sans-serif',
  borderRadius: 2,
}

export const API_URL = process.env.REACT_APP_API_URL || 'https://dev.api.portal.berlinpackaging.com/'
export const APIM_SUBSCRIPTION_KEY = process.env.REACT_APP_API_SUBSCRIPTION_KEY || 'b0da3957a2564cb1bfe0797a28b64e6d'
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? 'AIzaSyBxPi2qW3Y3ZkXmfIa07ILDLucOb6xuKdc'

export default config
