import {
  setIndustries,
  setCustomerRoles,
  setInterests,
  setUserIndustries,
  setUserInterests,
  setCustomers,
  setAddEditResp,
  setUploadCustomerStatus,
} from './userSlice'

import { AppThunk } from '../rootStore'
import { addRequest, removeRequest } from '../LoadingStore/loadingSlice'
import { Requests } from '../../types/common'
import { InviteTeamMembersParam } from '../../types/user'
import { UserRepository } from '../../repositories/UserRepository'
import { setUserInfo } from '../../stores/AuthStore/authSlice'
import { UserInfo } from '../../contexts/auth.context'

export const getIndustries = (): AppThunk => async (dispatch) => {
  try {
    dispatch(addRequest(Requests.getIndustries))

    const userRepository = UserRepository.getInstance()

    const industries = await userRepository.getIndustries()

    dispatch(setIndustries(industries))
  } catch (err) {
    console.log('[getindustries.error]', err)
  } finally {
    dispatch(removeRequest(Requests.getIndustries))
  }
}

export const getInterests = (): AppThunk => async (dispatch) => {
  try {
    dispatch(addRequest(Requests.getInterests))

    const userRepository = UserRepository.getInstance()

    const interests = await userRepository.getInterests()

    dispatch(setInterests(interests))
  } catch (err) {
    console.log('[getindustries.error]', err)
  } finally {
    dispatch(removeRequest(Requests.getInterests))
  }
}

export const getUserIndustries = (): AppThunk => async (dispatch) => {
  try {
    dispatch(addRequest(Requests.getUserIndustries))

    const userRepository = UserRepository.getInstance()

    const industries = await userRepository.getUserIndustries()

    dispatch(setUserIndustries(industries.map(({ id }) => id)))
  } catch (err) {
    console.log('[getUserIndustries.error]', err)
  } finally {
    dispatch(removeRequest(Requests.getUserIndustries))
  }
}

export const getUserInterests = (): AppThunk => async (dispatch) => {
  try {
    dispatch(addRequest(Requests.getUserInterests))

    const userRepository = UserRepository.getInstance()

    const interests = await userRepository.getUserInterests()

    dispatch(setUserInterests(interests.map(({ id }) => id)))
  } catch (err) {
    console.log('[getUserInterests.error]', err)
  } finally {
    dispatch(removeRequest(Requests.getUserInterests))
  }
}

export const uploadCustomerPhoto =
  (photo: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(addRequest(Requests.uploadCustomerPhoto))

      const userRepository = UserRepository.getInstance()

      await userRepository.uploadCustomerPhoto(photo)
    } catch (err) {
      console.log('[uploadCustomerPhoto.error]', err)
    } finally {
      dispatch(removeRequest(Requests.uploadCustomerPhoto))
    }
  }

export const getCustomerRoles = (): AppThunk => async (dispatch) => {
  try {
    dispatch(addRequest(Requests.getCustomerRoles))

    const userRepository = UserRepository.getInstance()

    const roles = await userRepository.getCustomerRoles()

    dispatch(setCustomerRoles(roles))
  } catch (err) {
    console.log('[getcustomerroles.error]', err)
  } finally {
    dispatch(removeRequest(Requests.getCustomerRoles))
  }
}

export const getCustomers =
  (payload: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(addRequest(Requests.getCustomers))

      const userRepository = UserRepository.getInstance()

      const customers = await userRepository.getCustomers(payload)
      dispatch(setCustomers(customers))
    } catch (err) {
      console.log('[getcustomers.error]', err)
    } finally {
      dispatch(removeRequest(Requests.getCustomers))
    }
  }

export const uploadCustomers =
  (payload: File): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(addRequest(Requests.uploadCustomers))

      const userRepository = UserRepository.getInstance()

      const uploadStatus = await userRepository.uploadCustomers(payload)

      uploadStatus === 202 && dispatch(setUploadCustomerStatus({ status: 200, message: 'success' }))
    } catch (err) {
      console.log('[getcustomers.error]', err)
      dispatch(setUploadCustomerStatus({ status: 500, message: 'error' }))
    } finally {
      dispatch(removeRequest(Requests.uploadCustomers))
    }
  }

export const createCustomers =
  (payload: any): AppThunk =>
  async (dispatch) => {
    try {
      const data = {
        user: {
          firstName: payload.firstName,
          lastName: payload.lastName,
          emailAddress: payload.email,
        },
        type: payload.type,
        primarySoldToId: payload.primarySoldTo,
        orderHistoryAvailabilityId: parseInt(payload.orderHistoryAvailabilityId),
        customOrderHistoryAvailabilityDate:
          parseInt(payload.orderHistoryAvailabilityId) === 6 ? payload.customOrderHistoryAvailabilityDate : null,
        customerIds: payload.customerIds,
        groupIds: payload.role,
        brandId: payload.brand === 'Berlin Packaging' ? 1 : 2, //parseInt(payload.brand)
      }
      dispatch(addRequest(Requests.createCustomers))

      const userRepository = UserRepository.getInstance()

      const res = await userRepository.createCustomers(data)
      dispatch(setAddEditResp({ type: 'add', status: res, data: { ...payload, name: `${payload.firstName} ${payload.lastName}` } }))
      dispatch(getCustomers('page=1&pageSize=20&sortField=createddate&sortOrder=desc&filter=textSearch='))
      return res
    } catch (err: any) {
      dispatch(
        setAddEditResp({
          type: 'add',
          status: 400,
          data: { ...payload, name: `${payload.firstName} ${payload.lastName}` },
          error: err.response.data ?? null,
        })
      )
      console.log('[createCustomers.error]', err)
    } finally {
      dispatch(removeRequest(Requests.createCustomers))
    }
  }

export const updateCustomers =
  (payload: any, id: string): AppThunk =>
  async (dispatch) => {
    try {
      const data = {
        user: {
          firstName: payload.firstName,
          lastName: payload.lastName,
          emailAddress: payload.email,
        },
        type: payload.type,
        primarySoldToId: payload.primarySoldTo,
        orderHistoryAvailabilityId: parseInt(payload.orderHistoryAvailabilityId),
        customOrderHistoryAvailabilityDate:
          parseInt(payload.orderHistoryAvailabilityId) === 6 ? payload.customOrderHistoryAvailabilityDate : null,
        customerIds: payload.customerIds,
        active: payload.active,
        groupIds: payload.role,
        brandId: payload.brand === 'Berlin Packaging' ? 1 : 2,
      }
      dispatch(addRequest(Requests.updateCustomers))
      const userRepository = UserRepository.getInstance()
      const res = await userRepository.updateCustomers(data, id)
      dispatch(setAddEditResp({ type: 'edit', status: res, data: payload }))
      // dispatch(getCustomers('page=1&pageSize=20&sortField=createddate&sortOrder=desc&filter=textSearch='))
    } catch (err: any) {
      dispatch(
        setAddEditResp({
          type: 'add',
          status: 400,
          data: { ...payload, name: `${payload.firstName} ${payload.lastName}` },
          error: err.response.data ?? null,
        })
      )
      console.log('[updateCustomers.error]', err)
    } finally {
      dispatch(removeRequest(Requests.updateCustomers))
    }
  }

export const inviteTeamMembers =
  (payload: InviteTeamMembersParam): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(addRequest(Requests.inviteTeamMembers))

      const userRepository = UserRepository.getInstance()
      await userRepository.inviteTeamMembers(payload)
    } catch (err) {
      console.log('[inviteteammembers.error]', err)
    } finally {
      dispatch(removeRequest(Requests.inviteTeamMembers))
    }
  }

export const updateUserIndustries =
  (ids: string[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(addRequest(Requests.updateUserIndustries))

      const userRepository = UserRepository.getInstance()

      await userRepository.updateUserIndustries(ids)
      dispatch(setUserIndustries(ids))
    } catch (err) {
      console.log('[updateuserindustries.error]', err)
    } finally {
      dispatch(removeRequest(Requests.updateUserIndustries))
    }
  }

export const updateUserInterests =
  (ids: string[]): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(addRequest(Requests.updateUserInterests))

      const userRepository = UserRepository.getInstance()

      await userRepository.updateUserInterests(ids)
      dispatch(setUserInterests(ids))
    } catch (err) {
      console.log('[updateuserinterests.error]', err)
    } finally {
      dispatch(removeRequest(Requests.updateUserInterests))
    }
  }

export const getCustomerPhoto = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(addRequest(Requests.getCustomerPhoto))
    const { authReducer } = getState()
    const user = authReducer.user
    const userRepository = UserRepository.getInstance()
    const imageUrl = await userRepository.getCustomerPhoto()
    dispatch(
      setUserInfo({
        ...(user as UserInfo),
        profileImg: imageUrl,
      })
    )
  } catch (err) {
    console.log('[getCustomerPhoto.error]', err)
  } finally {
    dispatch(removeRequest(Requests.getCustomerPhoto))
  }
}
