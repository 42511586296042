import { CanadaStates, UnitedStatesStates } from '../constants/states'

export interface Consignment {
  lineItems: ConsignmentLineItem[]
  address: NewCustomerAddress // shipping address
}

export interface ConsignmentLineItem {
  id: number | string
  quantity: number
}

export interface CustomerAddress extends NewCustomerAddress {
  storefrontAddressId: number
  customerId: string | null
  addressId: string
  taxExempt: boolean
  pendingTaxExemptRequest: boolean
  sequenceNumber: number // 1 means preferred
}

export interface CheckoutAddress extends NewCustomerAddress {
  sequenceNumber: number
  customerId?: string
}

export interface NewCustomerAddress {
  firstName: string
  lastName: string
  email: string
  company: string
  phone: string
  address1: string
  address2: string
  city: string
  stateOrProvince: string
  stateOrProvinceCode?: string
  postalCode: string
  countryCode: string
  billToAddress?: boolean
  shipToAddress?: boolean
  soldToAddress?: boolean
  addressId?: string
}

export interface IAddress {
  id: string
  firstName: string
  lastName: string
  businessName?: string
  phoneNumber: string
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  zipCode: string
  country: string

  deliveryNotes?: string
}

export type DeliveryMethod =
  | {
      shipping: ShippingOption
      payment: 'berlin'
    }
  | {
      shipping: ShippingOption
      payment: 'courier'
      method: string
      courier: string
      accountNumber: string
    }

export enum CreditCardType {
  Visa = 'visa',
  Mastercard = 'mastercard',
  AmEx = 'amex',
}

export interface ICreditCard {
  id: string
  last4: number
  expDate: string
  type: CreditCardType
}

export interface ShippingOption {
  id: string
  name: string
  cost: number
}

export type LTLShippingOptions = Record<string, { name: string; options: ShippingOption[] }>

export interface AvailableShippingOption {
  id: string
  description: string
  type: string
  imageUrl: string
  cost: number
  transitTime: string
  additionalDescription: string
}

export interface CheckoutPhysicalItem {
  id: string
  parentId: string | null
  sku: string
  alias: string | null
  url: string
  taxable: boolean | null
  imageUrl: string
  discountAmount: number
  couponAmount: number
  originalPrice: number
  listPrice: number
  soldAs: {
    identifier: string | null
    quantity: number
    name: string
  }
  unitOfMeasure: {
    identifier: string | null
    quantity: number
    name: string
  }
  salePrice: number
  extendedListPrice: number
  extendedSalePrice: number
  isRequireShipping: boolean
  isMutable: boolean
  productId: number
  quantity: number
  name: string
  variantId: number
  discounts: string[]
  optionSeletions: string | null
  minimumPurchaseQuantity: number
  maximumPurchaseQuantity: number
  hasInvalidPrice: boolean
  hasConflictingPriceTiers: boolean
}
export interface ConsignmentCart {
  id: string
  customerId: string
  channelId: number
  email: string
  currency: {
    code: string
  }
  taxIncluded: boolean
  baseAmount: number
  discountAmount: number
  cartAmount: number
  coupons: string[]
  lineItems: {
    physicalItems: CheckoutPhysicalItem[]
  }
  createdTime: string
  updatedTime: string
  locale: string | null
}

export interface ConsignmentBillingAddress extends CheckoutAddress {
  id: string
}

// export interface ConsignmentInfo {
//   id: string
//   address: CheckoutAddress
//   selectedPickupOption: string | null
//   availableShippingOptions: {
//     id: string
//     description: string
//     type: string
//     imageUrl: string
//     cost: number
//     transitTime: string
//     additionalDescription: string
//   }[]
//   selectedShippingOption: null
//   lineItemIds: string[]
//   shippingCostIncludingTax: number
//   shippingCostExcludingTax: number
//   handlingCostIncludingTax: number
//   handlingCostExcludingTax: number
//   couponDiscounts: string[]
//   discounts: string[]
// }

export interface AvailableShippingOption {
  id: string
  description: string
  type: string
  imageUrl: string
  cost: number
  transitTime: string
  additionalDescription: string
}

export interface ShippingOption {
  id: string
  name: string
  cost: number
  type?: string
}

export interface ConsignmentInfo {
  id: string
  address: CheckoutAddress
  selectedPickupOption: string | null
  availableShippingOptions: ShippingOption[]
  selectedShippingOption: null
  lineItemIds: string[]
  shippingCostIncludingTax: number
  shippingCostExcludingTax: number
  handlingCostIncludingTax: number
  handlingCostExcludingTax: number
  couponDiscounts: string[]
  discounts: string[]
}

export interface CheckoutInfo {
  id: string
  cart: ConsignmentCart
  billingAddress: ConsignmentBillingAddress
  consignments: ConsignmentInfo[]
  taxes: {
    name: string
    amount: number
  }[]
  coupons: string[]
  giftCertficaties: string[]
  orderId: string | number | null
  shippingCostTotalIncludingTax: number
  shippingCostTotalExcludingTax: number
  handlingCostTotalIncludingTax: number
  handlingCostTotalExcludingTax: number
  availableShippingOptions: ShippingOption[]
  taxTotal: number
  subTotalIncludingTax: number
  subTotalExcludingTax: number
  grandTotal: number
  customerMessage: string
  createdTime: string
  updatedTime: string
}

export interface OrderConfirmationLineItem {
  productId: number
  name: string
  sku: string
  quantity: number
  alias?: string | null
  imageUrl: string
  unitOfMeasure: {
    identifier: string
    quantity: number
    name: string
  }
  totalUnits: number
  unitCost: {
    subTotal: number
    tax: number
    total: number
  }
  totalCost: {
    subTotal: number
    tax: number
    total: number
  }
  hasInvalidPrice: boolean
  hasConflictingPriceTiers: boolean
}

export interface OrderConfirmationDetails {
  id: number
  orderDate: string // e.g. 2023-11-03T12:50:49+00:00
  billingAddress: CheckoutAddress
  consignments: {
    shippingAddress: CheckoutAddress
    lineItems: OrderConfirmationLineItem[]
  }[]
}

export interface SavedAddress {
  id: string
  nickname?: string
  streetAddress: string
  city: string
  state: string
  zipCode: string
  country: string
}

export interface TaxExempt {
  firstName: string
  lastName: string
  companyName: string
  state: string
  email: string
  phoneNumber: string
  taxCertificates: File[]
}

export interface AddressSuggestion {
  globalAddressKey: string
  text: string
  format: string
}

export interface AddressValidationSuggestions {
  moreResultsAvailable: boolean
  confidence: string
  suggestions: AddressSuggestion[]
}

export const toAddressValidationSuggestions = (data: {
  more_results_available: boolean
  confidence: string
  suggestions: { global_address_key: string; text: string; format: string }[]
}): AddressValidationSuggestions => {
  return {
    moreResultsAvailable: data.more_results_available,
    confidence: data.confidence,
    suggestions: data.suggestions?.map((suggestion) => ({
      globalAddressKey: suggestion.global_address_key,
      text: suggestion.text,
      format: suggestion.format,
    })),
  }
}

export interface FormattedAddressValidation {
  globalAddressKey: string
  confidence: string
  address: {
    addressLine1: string
    addressLine2: string
    addressLine3: string
    locality: string
    region: string
    postalCode: string
    country: string
  }
}

export const toFormattedAddressValidation = (data: {
  global_address_key: string
  confidence: string
  address: {
    address_line_1: string
    address_line_2: string
    address_line_3: string
    locality: string
    region: string
    postal_code: string
    country: string
  }
}): FormattedAddressValidation => {
  return {
    globalAddressKey: data.global_address_key,
    confidence: data.confidence,
    address: {
      addressLine1: data.address.address_line_1,
      addressLine2: data.address.address_line_2,
      addressLine3: data.address.address_line_3,
      locality: data.address.locality,
      region: data.address.region,
      postalCode: data.address.postal_code,
      country: data.address.country,
    },
  }
}

export const toCheckoutAddress = (address: CustomerAddress, email?: string): CheckoutAddress => {
  const formattedAddress: CheckoutAddress = {
    firstName: address.firstName,
    lastName: address.lastName,
    email: email ?? address.email ?? '',
    company: address.company ?? '',
    phone: address.phone,
    address1: address.address1 ? address.address1.trim() : '',
    address2: address.address2 ? address.address2.trim() : '',
    city: address.city,
    stateOrProvince: address.stateOrProvince,
    stateOrProvinceCode:
      address.stateOrProvinceCode ??
      UnitedStatesStates.find((states) => states.displayName === address.stateOrProvince)?.code ??
      CanadaStates.find((states) => states.displayName === address.stateOrProvince)?.code ??
      '',
    postalCode: address.postalCode,
    countryCode: address.countryCode,
    billToAddress: address.billToAddress,
    shipToAddress: address.shipToAddress,
    soldToAddress: address.soldToAddress,
    addressId: address.addressId,
    sequenceNumber: address.sequenceNumber,
  }
  if (address.customerId) {
    formattedAddress.customerId = address.customerId
  } else {
    formattedAddress.customerId = ''
  }
  return formattedAddress
}

export interface ExperianAddress {
  address1: string
  address2?: string
  city?: string
  stateOrProvince?: string
  postalCode?: string
  countryCode?: string
}
