import { RootState } from '../rootReducer'

export const selectOpenedMenu = ({ uiReducer }: RootState) => uiReducer.isOpen

export const selectMenuState = ({ uiReducer }: RootState) => uiReducer.opened

export const selectThemeMode = ({ uiReducer }: RootState) => uiReducer.mode

export const selectFeatureFlags = ({ uiReducer }: RootState) => uiReducer.featureFlags

export const selectFeatureFlag =
  (featureFlag: string) =>
  ({ uiReducer }: RootState) =>
    uiReducer.featureFlags && uiReducer.featureFlags[featureFlag] === true

export const selectUiState = ({ uiReducer }: RootState) => uiReducer
